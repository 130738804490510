import { graphql } from 'gatsby';
import React from 'react';
import { adaptParagraphs } from '@src/adapters/paragraph';
import Paragraph from '@src/components/paragraph';
import LayoutFromContent from '@src/layouts/layout-from-content';
import { ApproachQuery } from '@types';
import styles from './mon-approche.module.scss';
import ContactForm from '@src/components/contact-form';

interface ApproachProps {
  data: ApproachQuery;
}

const ApproachPage: React.FC<ApproachProps> = ({ data }) => {
  const paragraphsData = adaptParagraphs(data.paragraphs);
  const visionData = paragraphsData.find(e => e.id == 'vision');
  const decouverteData = paragraphsData.find(e => e.id == 'decouverte');
  const deroulementData = paragraphsData.find(e => e.id == 'deroulement');
  const consultationData = paragraphsData.find(e => e.id == 'consultation');

  return (
    <LayoutFromContent pageId="approche">
      <div className={styles.page}>
        <div className={styles.container}>
          {visionData && (
            <div className={styles.vision}>
              <div className={styles.content}>
                <Paragraph paragraph={visionData} />
              </div>
            </div>
          )}

          {decouverteData && (
            <div className={styles.decouverte}>
              <div className={styles.content}>
                <Paragraph paragraph={decouverteData} hLevel={2} />

                <div className={styles.videos}>
                  <iframe
                    title="Ostéopathie en faune sauvage"
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCentreSauvegardeFauneSauvagedesSavoie%2Fvideos%2F1089807502142055%2F&show_text=true&width=560&t=0"
                    height="540"
                    style={{ border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </div>
              </div>
            </div>
          )}

          {deroulementData && (
            <div className={styles.deroulement}>
              <div className={styles.content}>
                <Paragraph paragraph={deroulementData} hLevel={2} />
              </div>
            </div>
          )}

          {consultationData && (
            <div className={styles.consultation}>
              <div className={styles.content}>
                <Paragraph paragraph={consultationData} hLevel={2} />
              </div>
            </div>
          )}
        </div>

        <ContactForm />
      </div>
    </LayoutFromContent>
  );
};

export const query = graphql`
  query Approach {
    paragraphs: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "paragraph" } }
        frontmatter: {
          id: { in: ["vision", "decouverte", "deroulement", "consultation"] }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            centered
            image {
              childImageSharp {
                fluid(maxWidth: 330, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            right
          }
        }
      }
    }
  }
`;

export default ApproachPage;
