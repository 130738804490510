import {
  MarkdownRemark,
  MarkdownRemarkFrontmatter,
  MarkdownRemarkFrontmatterCta,
  ParagraphData,
  GatsbyImageSharpFluid_WithWebpFragment,
} from '@types';
import { adaptImage } from './image';

type ParagraphFrontmatter =
  | (Pick<
      MarkdownRemarkFrontmatter,
      'id' | 'title' | 'centered' | 'right' | 'alt'
    > & {
      image?:
        | {
            childImageSharp?:
              | {
                  fluid?:
                    | GatsbyImageSharpFluid_WithWebpFragment
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      cta?:
        | Pick<MarkdownRemarkFrontmatterCta, 'cta_text' | 'cta_url'>
        | null
        | undefined;
    })
  | null
  | undefined;

export const adaptParagraph = (
  data:
    | (Pick<MarkdownRemark, 'html'> & {
        frontmatter?: ParagraphFrontmatter;
      })
    | undefined,
): ParagraphData | null => {
  if (data?.frontmatter?.id && data?.frontmatter?.title) {
    const cta =
      data?.frontmatter?.cta?.cta_text && data?.frontmatter?.cta?.cta_url
        ? {
            url: data?.frontmatter?.cta?.cta_url,
            text: data?.frontmatter?.cta?.cta_text,
          }
        : undefined;

    return {
      id: data.frontmatter.id,
      title: data.frontmatter.title,
      centered: data.frontmatter.centered || false,
      content: data.html || undefined,
      image: adaptImage(data.frontmatter.image),
      alt: data.frontmatter.alt || undefined,
      right: data.frontmatter.right || undefined,
      cta,
    };
  }
  return null;
};

export const adaptParagraphs = (data: {
  edges: {
    node: Pick<MarkdownRemark, 'html'> & {
      frontmatter?: ParagraphFrontmatter;
    };
  }[];
}): ParagraphData[] => {
  return data.edges
    .map(e => adaptParagraph(e.node))
    .flatMap(e => (e !== null ? [e] : []));
};
